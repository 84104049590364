import React from 'react'


const Payment_Failed = () => {
  return (
    <>
      <div className="box"></div>
      <div style={{ marginLeft: "auto" }} className="">
        <h1>Failed</h1>
      </div>
    </>
  );
}

export default Payment_Failed